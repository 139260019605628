import { render, staticRenderFns } from "./integration-container.vue?vue&type=template&id=0d523550&"
import script from "./integration-container.vue?vue&type=script&lang=js&"
export * from "./integration-container.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports